<template>

<!-- Main container (when everything is ready) -->
<div v-if="loaded" id="main">

    <!-- Smile and Learn header -->
    <smile-header 
      :welcome="false"
      :show-button="false"
    />

    <!-- Header with the total grade -->
    <gradebook-info 
      :course-name="courseName"
      :activity-name="activityName"
      :student-name="studentName" 
      :grade="grade"
      :passed="passed"
    />

    <!-- Grade details -->
    <div id="GradeDetails" class="grades-detail-container">

      <!-- When the activity is a course. -->
      <vue-collapsible-panel-group v-if="isCourse" accordion style="--border-color: #3a81a5; --bg-color-header: #88BEEF; --bg-color-header-hover: #F2F8FF; --bg-color-header-active: #F2F8FF">
        <vue-collapsible-panel v-for="p in contents" :key="p.path.id" :expanded="false">
            <template #title>
              <span class="content-name">
                <img :src="p.path.icon_url" :alt="p.path.name">
                <div>
                  <p class="title"> {{ p.path.name }} </p>
                </div>
              </span>
            </template>
            <template #content>
              <gradebook-details 
                v-for="content in p.contents" 
                :key="content.id" 
                :content="content"
                :grades="gradeDetails[content.id]"
                @manage-drawer="manageDrawer"
              />
            </template>
          </vue-collapsible-panel>
      </vue-collapsible-panel-group>

      <!-- When the activity is not a course. -->
      <gradebook-details 
        v-else
        v-for="content in contents" 
        :key="content.id" 
        :content="content"
        :grades="gradeDetails[content.id]"
        @manage-drawer="manageDrawer"
      />
    </div>

    <!-- Contents information panel -->
    <drawer-content
      :visible="drawerShow"
      :token="token"
      :content-id="drawerContentId"
      :lang="lang"
      :show-add-button="false"
      :is-already-added="false"
      @manage-drawer="manageDrawer"
    />

  </div>

</template>

<script>

// Methods
import { 
  getActivity,
  getContentDetails,
  getRouteDetails,
  getGrades,
} from '@/lib/functions'

// Graphic components
import DrawerContent from '@/components/DrawerContent.vue'
import GradebookDetails from '@/components/GradebookDetails.vue'
import GradebookInfo from '@/components/GradebookInfo.vue'
import SmileHeader from '@/components/SmileHeader.vue'
import { VueCollapsiblePanelGroup, VueCollapsiblePanel } from '@dafcoe/vue-collapsible-panel'


export default {

  // Component name.
  name: 'GradeBook',

  // Components.
  components: {
    DrawerContent,
    GradebookDetails,
    GradebookInfo,
    SmileHeader,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },

  // Data.
  data () {
    return {
      // API key (client token).
      apiKey: null,

      // API token.
      token: null,

      // Course data.
      courseName: null,

      // Activity data.
      activityId: null,
      activityName: null,
      contents: null,
      lang: null,

      // Whether the activity is a course.
      isCourse: false,

      // Whether page is fully loaded.
      loaded: false,

      // Student data.
      studentId: null,
      studentName: null,

      // Grade.
      grade: null,

      // Whether the activity is passed.
      passed: null,

      // Details of grade.
      gradeDetails: [],
      completionDetails: [],

      // Whether drawer must be shown.
      drawerShow: false,

      // Content ID to be displayed in the drawer.
      drawerContentId: null,
    }
  },

  // Code executed when the component is created.
  async created () {
    // Retrieves the GET parameters.
    this.apiKey = this.$route.query.apiKey

    // Handle scroll event.
    window.onscroll = this.handleScroll

    // Listener for capturing Moodle events.
    window.onmessage = (event) => this.handleMoodle(event.data)

    // Site is finally loaded.
    this.messageReady()
  },

  // Methods.
  methods: {
    /**
     * Build contents structure from contents IDs.
     */
    async buildContents (contents) {
      // Loads the accordion (if exists).         
      if (Array.isArray(contents[0])) {
        this.isCourse = true;
        return Promise.all(contents.map( async p => ({ 'path': await getRouteDetails(this.token, this.lang, p[0]), 'contents': await Promise.all(p[1].map( c => getContentDetails(this.token, this.lang, c))) })))
      } else {
        return Promise.all(contents.map( c => getContentDetails(this.token, this.lang, c) ))
     }
    },

    /**
     * Shows or hides the drawer.
     */
    manageDrawer (content_id) {
      this.drawerContentId = content_id
      this.drawerShow = content_id !== null
    },

    /**
     * Messages to the parent when ready.
     */
    messageReady () {
      parent.postMessage({
        type: 'smile.lti.ready',
      }, '*')
    },

    /** 
     * Handle Moodle events.
     * This event will only occur when Moodle is in edition mode.
     * Therefore, we should prepare everything to allow activity edition.
     */
    async handleMoodle (data) {
      if (data.type == 'smile.moodle') {
        this.token = data.token
        this.courseName = data.courseName
        this.activityId = data.activityId
        this.activityName = data.activityName
        this.studentId = data.studentId
        this.studentName = `${data.studentFirstName} ${data.studentLastName}`
        this.grade = data.grade
        this.passed = data.passed
        const activity = await getActivity(this.apiKey, this.activityId)
        if (activity) {
          this.lang = activity.lang
          this.contents = await this.buildContents(activity.contents)
        }
        this.gradeDetails = await getGrades(this.apiKey, this.activityId, this.studentId)
        this.loaded = true
      }
    },
    
    /**
     * Handles the scroll of the site.
     */
    handleScroll () {      
      var header = document.getElementById("sl-search")
      var contents = document.getElementById("sl-contents")
      let height = header.offsetHeight
      if (contents) {
        if (window.pageYOffset > 278) {
          header.classList.add("sticky")
          contents.style.marginTop = height + 'px'
        } else {
          header.classList.remove("sticky")
          contents.style.marginTop = '0px'
        }
      }
    }
  
  }
}
</script>

<style scoped> 
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3f87c7;
}
.grades-detail-container {
  padding: 2em 4em 0 2em;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 60px 0;
}
.content-name {
  text-align: left;
}
.content-name img {
  float: left;
  margin-right: 10px; 
  cursor: pointer; 
  height: 40px;
}
.content-name div {
  margin-left: 50px;
}
.content-name .title {
  margin: 0;
  font-weight: 500;
  color: white;
  font-size: 14pt;
  font-weight: 900;
  margin-top: 0.5em;
}
.vcp__header:hover .title {
  color: black;
}
.content-name .type {
  margin: 0;
  font-weight: 100;
}
</style>

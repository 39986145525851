<template>

  <!-- Container -->
  <div id="main" class="contents-outer vertical-center">
      <!-- Header-->
      <img class="heading" src="../assets/sad_seal.jpg">

      <!-- Error details -->
      <div class="contents-inner">
        <h3>Esta actividad no puede ser editada</h3>
        <p>Los contenidos pertenecientes a Programas de Formación (ODEs de nivel 4) no pueden ser editados</p>
      </div>        
  </div>

</template>


<style scoped>
.contents-outer {
  text-align: center;
}
.contents-inner {
  background-color: white; 
  max-width: 600px; 
  margin: auto; 
  padding: 1em 0;
}
.heading {
  max-width: 600px; 
  margin: auto;
  border: 0;
}
.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
</style>
<template>
<!-- Main container (when everything is ready) -->
<div v-if="loaded" id="main">

  <!-- Smile and Learn header -->
  <smile-header 
    v-if="!mobile"
    :welcome="false"
    :show-button="false"
    :api-key="apiKey"
    :token="token"
    :activity-id="activityId"
  />

  <!-- Content selector -->
  <div class="selector-container" v-if="contents.length > 0 && !selectedContent">
    <div class="selector">
      <h3>Selecciona el contenido que quieras jugar:</h3>
      <div class="tasks">
        <!-- When the activity is a course. -->
        <vue-collapsible-panel-group v-if="isCourse" accordion style="--border-color: #3a81a5; --bg-color-header: #88BEEF; --bg-color-header-hover: #F2F8FF; --bg-color-header-active: #F2F8FF">
          <vue-collapsible-panel v-for="p in contents" :key="p.path.id" :expanded="false">
              <template #title>
                <span class="content-name">
                  <img :src="p.path.icon_url" :alt="p.path.name">
                  <div>
                    <p class="title"> {{ p.path.name }} </p>
                  </div>
                </span>
              </template>
              <template #content>
                <div v-for="c in p.contents" :key="c.id" @click="loadContent(c)" class="task">
                  <play-item :content="c" :completed="isCompleted(c.id)" />
                </div>
              </template>
            </vue-collapsible-panel>
        </vue-collapsible-panel-group>

        <!-- When the activity is not a course. -->
        <div v-else v-for="c in contents" :key="c.id" @click="loadContent(c)" class="task" >
          <play-item :content="c" :completed="isCompleted(c.id)" />
        </div>
      </div>
    </div>   
  </div>

  <!-- Content -->
  <div class="content" v-if="selectedContent">
    <div class="inline-block">

      <!-- Title-->
      <h3>
        {{ selectedContent.title }} ({{ typeName(processedType) }}) en {{ langName(lang) }}
      </h3>

      <!-- Button to go back -->
      <button 
        class="sl-searcher-button pointer"
        @click="clear">
        Seleccionar otro contenido
      </button>
    </div>

      <!-- Content frames -->
      <span v-if="isVideo || isAudio">
        <video 
          v-if="isVideo"
          id="video"
          class="content-video" 
          :src="contentUrl"
          crossorigin="anonymous"
          controls
          disablePictureInPicture 
          controlsList="nodownload noplaybackrate" 
          :autoplay="true"
          :muted="muted"
          @contextmenu.prevent="false"
          @play="play(true)"
          @pause="pause(true)"
          @volumechange="checkMuted"
          @ended="clear"
        >
          <track 
            v-for="([trackLang, trackUrl]) in Object.entries(contentCaptions)" 
            :key="trackLang"
            :label="capitalize(langName(trackLang))" 
            kind="subtitles" 
            :srclang="trackLang" 
            :src="trackUrl" 
            :default="trackLang == lang"
          />
        </video>
        <audio
          v-if="isAudio"
          id="video"
          class="content-audio"
          :src="contentUrl"
          controls
          controlsList="nodownload noplaybackrate" 
          :autoplay="true"
          @contextmenu.prevent="false"
          @play="play(true)"
          @pause="pause(true)"
          @ended="clear"
        ></audio>
        <div 
          id="controls" 
          class="center"
        >
          <img 
            class="control-icon" 
            :src="iconPlayPause"
            @click="playOrPause"
          />
          <img 
            v-if="isVideo"
            class="control-icon" 
            :src="iconMuteUnmute"
            @click="muteOrUnmute"
          />
        </div>
      </span>

      <div v-else id="screen" class="screen" @fullscreenchange="handleFullscreen">
        <img id="fullscreen-button" class="sl-fullscreen-button" :src="iconFullScreen" @click="toggleFullscreen" />
        <iframe 
          id="webgl" 
          class="content-frame" 
          allow="autoplay; fullscreen"
          :src="contentUrl"
          frameborder="0"
          @load="adjustFullscreenButton()"
        ></iframe>
      </div>

      <span v-if="isPDF">
        <div 
          id="controls" 
          class="center"
        >
          <a :href="contentUrl" target="_blank">
            <img 
              class="control-icon" 
              :src="iconDownload"
            />
          </a>
        </div>
      </span>

    </div>

</div>

<!-- Alternative container when data is loading -->
<div v-else>
  <div class="unloaded">
    <span class="bg-lineas-diagonales">
      Cargando...
    </span>
  </div>
</div>

</template>

<script>

// Routes
import { URL_LTI_FEEDBACK } from '@/api/routes'

// Constants
import { 
  IS_LOCAL,
  LANG_IDS,
  FEEDBACK_TYPES,
  MIN_VIDEO_DURATION,
  TYPE_NAMES,
  LANG_NAMES
} from '@/lib/constants'

// Methods
import { 
  validateApiKey, 
  getActivity, 
  getContentDetails,
  getRouteDetails,
  feedback,
  getCompletion,
} from '@/lib/functions'

// Helpers
import { encodeB64, encrypt } from '@/lib/helpers'

// Graphic components
import SmileHeader from '@/components/SmileHeader.vue'
import PlayItem from '@/components/PlayItem.vue'
import { VueCollapsiblePanelGroup, VueCollapsiblePanel } from '@dafcoe/vue-collapsible-panel'

// Styles
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default {

  // Components.
  components: {
    SmileHeader,
    PlayItem,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },

  // Data.
  data () {
    return {
      // Mobile mode.
      mobile: false,

      // API key (client token).
      apiKey: null,

      // API token.
      token: null,

      // Activity id.
      activityId: null,

      // Student id.
      studentId: null,

      // Whether page is fully loaded.
      loaded: false,

      // Activity language.
      lang: null,

      // Activity contents.
      contents: [],

      // Whether the activity is a course.
      isCourse: false,

      // Completion data.
      contentsCompleted: {},

      // Selected content.
      selectedContent: null,

      // Whether video is playing.
      playing: false,

      // Whether video is muted.
      muted: false,

      // Registers the start of an event.
      start: null,

      // Registers the duration of the current event.
      duration: 0,

      // Stores the whole set of feedback data.
      feedbackData: {
        lt1: URL_LTI_FEEDBACK,
        lms: 'lti',
      },

      // Whether the player is on full screen mode.
      fullscreen: false,
    }
  },

  // Code executed when the component is created.
  async created () {
    // Retrieves the GET parameters.
    this.apiKey = this.$route.query.apiKey
    this.token = this.$route.query.token
    this.activityId = parseInt(this.$route.query.activityId)
    this.mobile = this.$route.query.mobile ?? false

    // Validates the API key.
    if (!(await validateApiKey(this.apiKey))) this.$router.push('/no-license')

    // Sets the API key in the feedback data.
    this.feedbackData['ap1k'] = this.apiKey

    // Listener for capturing Moodle events.
    if (this.mobile) {
      this.handleMoodle({
        type: 'smile.moodle',
        token: this.$route.query.token,
        activityId: parseInt(this.$route.query.activityId),
        studentId: parseInt(this.$route.query.studentId),
        wsAddress: this.$route.query.wsAddress,
        wsToken: this.$route.query.wsToken,
        moduleId: parseInt(this.$route.query.moduleId),
        instanceId: parseInt(this.$route.query.instanceId),
      })
    } else {
      window.onmessage = (event) => this.handleMoodle(event.data)
    }

    // Listener for pausing video when changing tab.
    document.onvisibilitychange = () => document.hidden ? this.pause() : null
    
    // Listener for clearing and feedback before exiting.
    // window.onbeforeunload = () => { 
      // event.returnValue = 'onbeforeunload'
      // this.clear()
      // return 'onbeforeunload'
    // }

    // Site is finally loaded.
    this.loaded = true
    this.messageReady()
  },

  beforeUnmount () {
    this.clear()
  },

  // Computed properties.
  computed: {
    /**
     * Whether the selected content is a video.
     */
    isVideo () {
      return this.selectedContent?.type == 'video'
    },

    /**
     * Whether the selected content is an audio.
     */
     isAudio () {
      return this.selectedContent?.type == 'customaudiobook'
    },

    /**
     * Whether the selected content is a custom quiz.
     */
     isQuiz () {
      return this.selectedContent?.type == 'customquiz'
    },

    /**
     * Whether the selected content is a video.
     */
    isPDF () {
      return this.selectedContent?.type == 'pdf'
    },

    /**
     * Returns the processed type for custom contents.
     */
    processedType () {
      return this.selectedContent.type.replace('custom', '')
    },

    /**
     * Whether a PDF / video content is completed.
     */
    completed () {
      if (this.isVideo || this.isAudio) {
        const videoDuration = document.getElementById('video').duration
        return + (this.duration / videoDuration > MIN_VIDEO_DURATION)
      }
      return 1
    },

    /**
     * Feedback data for WebGL.
     */
    webglFeedback () {
      return { 
        ...this.feedbackData, 
        _c1d: this.selectedContent.id,
        l4ngs: JSON.stringify([ LANG_IDS[this.lang] ]), 
      }
    },

    /**
     * Feedback data for PDFs and videos.
     */
    mediaFeedback () {
      return { 
        ...this.feedbackData, 
        _c1d: this.selectedContent.id,
        _typ: FEEDBACK_TYPES.indexOf(this.processedType),
        _l4ng: this.lang,
        _sc0r: 0,
        _tsc0r: 0,
        _f4l: 0,
        _l3v4pp: 0,
        _dur: this.duration,
        _c0mpl: this.completed,
      }
    },

    /**
     * Feedback data for quizzes.
     */
    quizFeedback () {
      return {
        feedback_key: 'lti',
        feedback_url: encodeB64(URL_LTI_FEEDBACK, false),
        feedback_params: encodeB64(JSON.stringify({
          ...Object.fromEntries(Object.entries(this.feedbackData).map(([k, v]) => [k, encrypt(v, k != 'lt1' && k != 'l4ngs')])),
          _c1d: encrypt(this.selectedContent.id),
        }), false)
      }
    },

    /**
     * URL of the content.
     */
    contentUrl () {
      if (['pdf', 'video', 'customaudiobook'].includes(this.selectedContent.type)) {
        return this.selectedContent.url[this.lang]
      } else {
        let url = ''
        let query = ''
        if (this.isQuiz) {
          url = this.selectedContent.url[this.lang]
          query = this.buildQueryParams(this.quizFeedback, false)
        } else {
          url = this.selectedContent.url
          query = this.buildQueryParams(this.webglFeedback)
        }
        url = IS_LOCAL ? url.replace('https', 'http') : url
        return `${url}?${query}`
      }
    },

    /**
     * URLs of available captions.
     */
    contentCaptions () {
      if (!this.selectedContent.captions) return {}
      return Object.fromEntries(Object.entries(this.selectedContent.captions).filter((c) => c[1] != ''))
    },

    /**
     * Icon of the play / pause icon.
     */
    iconPlayPause () {
      return require(`../assets/icon_video_${ this.playing ? 'pause' : 'play' }.png`)
    },

    /**
     * Icon of the mute / unmute icon.
     */
    iconMuteUnmute () {
      return require(`../assets/icon_video_${ this.muted ? 'unmute' : 'mute' }.png`)
    },

    /**
     * Icon of the download icon.
     */
     iconDownload () {
      return require(`../assets/icon_pdf_download.png`)
    },

    /**
     * Icon of the full screen toggle.
     */
    iconFullScreen () {
      return require(`../assets/icon_fs_${ this.fullscreen ? 'off' : 'on' }.png`)
    },
  },

  // Methods
  methods: {
    /**
     * Build contents structure from contents IDs.
     */
    async buildContents (contents) {
      // Loads the accordion (if exists).         
      if (Array.isArray(contents[0])) {
        this.isCourse = true;
        return Promise.all(contents.map( async p => ({ 'path': await getRouteDetails(this.token, this.lang, p[0]), 'contents': await Promise.all(p[1].map( c => getContentDetails(this.token, this.lang, c))) })))
      } else {
        return Promise.all(contents.map( c => getContentDetails(this.token, this.lang, c) ))
     }
    },

    /**
     * Properly formatted content type name.
     */
     typeName (type) {
      return TYPE_NAMES[type]
    },

    /**
     * Properly formatted content type name.
     */
    langName (lang) {
      return LANG_NAMES[lang]
    },

    /**
     * Load a content on selection.
     */
    loadContent (content) {
      this.selectedContent = content
      if (this.isPDF) this.start = Date.now()
    },

    /**
     * Clears the contents.
     */
    async clear () {
      if (this.isVideo || this.isAudio || this.isPDF) {
        if (this.playing) this.duration += (Date.now() - this.start)
        this.duration /= 1000
        await this.feedback()
      }
      this.selectedContent = null
      this.playing = false
      this.start = null
      this.duration = 0

      // Reload completion.
      this.updateCompletion()
    },

    /**
     * Plays a video.
     */
    play (fromPlayer = false) {
      if ((this.isVideo || this.isAudio) && !fromPlayer) {
        const video = document.getElementById('video')
        video.play()
      }
      this.start = Date.now()
      this.playing = true
    },

    /**
     * Pauses a video.
     */
    pause (fromPlayer = false) {
      if ((this.isVideo || this.isAudio) && !fromPlayer) {
        const video = document.getElementById('video')
        video.pause()
      }
      if (this.start) this.duration += (Date.now() - this.start)
      this.start = null
      this.playing = false
    },

    /**
     * Switches between playing / pausing a video based on current state.
     */
    playOrPause () {
      this.playing ? this.pause() : this.play()
    },

    /**
     * Switches between muting / unmuting a video based on current state.
     */
    muteOrUnmute () {
      this.muted = !this.muted
    },

    /**
     * Checks if the video player is muted.
     */
    checkMuted () {
      const video = document.getElementById('video')
      this.muted = video.muted || video.volume == 0
    },

    /**
     * Retrieves the activity information. 
     */
    async updateActivity () {
      const activity = await getActivity(this.apiKey, this.activityId, this.studentId)
      if (activity) {
        this.lang = activity.lang
        this.contents = await this.buildContents(activity.contents)
      }
      this.feedbackData['_act1d'] = this.activityId
    },

    /**
     * Retrieves the completion from the database.
     */
    async updateCompletion () {
      if (this.activityId && this.studentId) {
        this.contentsCompleted = await getCompletion(this.apiKey, this.activityId, this.studentId)
      }
    },

    /**
     * Whether a content is completed.
     */
    isCompleted (contentId) {
      return this.contentsCompleted[contentId] ?? false
    },

    /**
     * Builds the query params for feedback data.
     */
    buildQueryParams (data, encrypted = true) {
      if (encrypted) {
        return Object.entries(data).map(([k, v]) => `${k}=${encrypt(v, k != 'lt1' && k != 'l4ngs')}`).join('&')
      } else {
        return Object.entries(data).map(([k, v]) => `${k}=${v}`).join('&')
      }
    },

    /**
     * Displays a string with the first letter capitalized.
     */
    capitalize (s) {
      return s.charAt(0).toUpperCase() + s.substr(1)
    },

    /**
     * Toggles full screen mode.
     */
    toggleFullscreen () {
      if (!this.fullscreen) document.getElementById('screen').requestFullscreen()
      else document.exitFullscreen()
    },

    /**
     * Handles the full screen mode.
     */
    handleFullscreen () {
      this.fullscreen = !this.fullscreen
      setTimeout(() => this.adjustFullscreenButton(), 100);
    },

    /**
     * Adjusts the position of the full screen button.
     */
    adjustFullscreenButton () {
      const frm = document.getElementById('webgl')
      const btn = document.getElementById('fullscreen-button')
      if (frm && btn) {
        if (this.isQuiz) btn.style['width'] = '4%';
        else btn.style['width'] = '5.8%';
        const frmRect = frm.getBoundingClientRect()
        let btnRect = btn.getBoundingClientRect()
        if (this.isQuiz) {
          const varPctW = this.fullscreen ? 0.995 : 0.99
          const varPctH = this.fullscreen ? 0.01 : 0.02
          btn.style['margin-top'] = `${frmRect.height * varPctH}px`;
          btn.style['margin-left'] = `${frmRect.width * varPctW - btnRect.width}px`
        } else {
          const ratio = frmRect.width / frmRect.height
          if (ratio > 1.6) {
            const realWidth = frmRect.height * 1.6
            const margin = (frmRect.width - realWidth) / 2
            btn.style['margin-left'] = `${frmRect.width - margin - btnRect.width - realWidth * 0.01}px`
            btn.style['margin-top'] = `${frmRect.height * 0.02}px`
          } else {
            const realHeight = frmRect.width / 1.6
            const margin = (frmRect.height - realHeight) / 2
            btn.style['margin-left'] = `${frmRect.width * 0.99 - btnRect.width}px`
            btn.style['margin-top'] = `${margin + realHeight * 0.02}px`
          }
        }
        btnRect = btn.getBoundingClientRect()
        const btnHeight = btnRect.height + Number.parseFloat(btn.style.marginTop)
        frm.style['margin-top'] = `${-btnHeight}px`
      }
    },

    /**
     * Sends feedback for non-WebGL contents.
     */
    async feedback () {
      const data = Object.fromEntries(Object.entries(this.mediaFeedback).map(([k, v]) => [k, encrypt(v)]))
      await feedback(data)
    },

    /**
     * Messages to the parent when ready.
     */
    messageReady () {
      parent.postMessage({
        type: 'smile.lti.ready',
      }, '*')
    },

    /** 
     * Handle Moodle events.
     * This event will only occur when Moodle is in edition mode.
     * Therefore, we should prepare everything to allow activity edition.
     */
    async handleMoodle (data) {
      if (data.type == 'smile.moodle') {
        this.token = data.token
        this.activityId = data.activityId
        this.studentId = data.studentId

        // Sets additional data for the feedback.
        this.feedbackData['lms'] = 'moodle'
        this.feedbackData['w4ddr'] = data.wsAddress
        this.feedbackData['wt0k'] = data.wsToken
        this.feedbackData['a1d'] = data.moduleId
        this.feedbackData['i1d'] = data.instanceId
        this.feedbackData['s1d'] = data.studentId

        // Loads the activity.
        await this.updateActivity()

        // Load completion.
        await this.updateCompletion()
      }      
    },
  }
}
</script>

<style scoped>
.selector-container {
  text-align: center; 
  margin-top: 20px;
}
.selector {
  max-width: 600px; 
  margin: auto; 
  padding: 1em 0;
  border-radius: 10px;
}
.tasks {
  padding: 0 20px;
}
.content {
  padding: 0 20px;
}
.content-name {
  text-align: left;
}
.content-name img {
  float: left;
  margin-right: 10px; 
  cursor: pointer; 
  height: 40px;
}
.content-name div {
  margin-left: 50px;
}
.content-name .title {
  margin: 0;
  font-weight: 500;
  color: white;
  font-size: 14pt;
  font-weight: 900;
  margin-top: 0.5em;
}
.vcp__header:hover .title {
  color: black;
}

.content-name .type {
  margin: 0;
  font-weight: 100;
}
.screen {
  margin: 0 auto;
  height: 80vh;
  aspect-ratio: 1.6;
  width: calc(80vh * 1.6);
}
.sl-fullscreen-button {
  position: relative;
  z-index: 1;
  width: 0;
  cursor: pointer;
}

.content-frame {
  position: relative;
  width: 100%; 
  height: 100%;
}
.content-video {
  width: 100%; 
  height: calc(100vh - 200px);
}
.content-audio {
  width: 100%; 
}
.control-icon {
  width: 64px; 
  margin-right: 8pt; 
  cursor: pointer;
}
.unloaded {
  text-align: center; 
  margin: 20px;
}
.unloaded span {
  color: white; 
  padding: 10px 20px; 
  font-weight: 600; 
  border-radius: 10px;
}

</style>
<template>

<!-- Main container (when everything is ready) -->
<div v-if="loaded" id="main">

  <!-- Smile and Learn header -->
  <smile-header 
    :welcome="false"
    :show-button="!editing"
    :api-key="apiKey"
    :token="token"
    :activity-id="activityId"
    :lang="lang"
  />

  <!-- Wizard for language selection (only before lang is selected) -->
  <div v-if="!lang" class="vertical-center">
    <language-wizard @set-lang="changeLang" />
  </div>

  <!-- Contents catalog -->
  <div v-else>

    <!-- Heading message -->
    <div class="catalog-message">
      <p>Utiliza esta opción para buscar recursos digitales en {{ langName }} y realizar tu propia selección personalizada de objetos de aprendizaje. Podrás incluir uno o más recursos de toda la colección de contenidos de Smile and Learn.</p>
    </div>

    <!-- Search module -->
    <search-engine
      id="sl-search"
      :lang="lang"
      :searchPaths="false"
      :editing="editing || customizing"
      :searching="searching"
      @search="search" />

    <!-- Contents panel (only when some contents are to be displayed) -->
    <div id="sl-contents" class="sl-contents" v-if="resultContents.length > 0 || selectedContents.length > 0">

      <!-- Search results -->
      <div class="item item-main" v-if="resultContents.length > 0" >
        <div class="contents">
          <item-content
            v-for="item in resultContents" 
            :key="item.id" 
            :item="item" 
            :is-selected="isSelected(item.id)"
            @toggle-select-content="clickItem"
            @manage-drawer="manageDrawer"
          />
        </div>
      </div>

      <!-- Summary of selection -->
      <div class="item item-summary">
        <div class="hide-when-small sl-contents-header">
          <h3>Contenidos Seleccionados</h3>
        </div>

        <!-- Allows drag and drop for reorder -->
        <template v-if="selectedContents.length > 0">
          <draggable
            class="draggable"
            v-model="selectedContents" 
            group="people" 
            @start="dragging=true" 
            @end="dragging=false" 
            item-key="id"
            @change="moveContent"
          >
            <template #item="{element}">
              <summary-list-item 
                @manage-drawer="manageDrawer"
                @toggle-select-content="clickItem"
                :item="element"
              />
            </template>
          </draggable>
        </template>
      </div>
    </div>

    <!-- Contents information panel -->
    <drawer-content
      :visible="drawerShow"
      :token="token"
      :content-id="drawerContentId"
      :lang="lang"
      :show-add-button="true"
      :is-already-added="isDrawerContentSelected"
      @toggle-select-content="clickItem"
      @manage-drawer="manageDrawer"
    />
  </div>
</div>

<!-- Alternative container when data is loading -->
<div v-else>
  <div class="unloaded">
    <span class="bg-lineas-diagonales">
      Cargando...
    </span>
  </div>
</div>

</template>

<script>
// Libraries
import draggable from 'vuedraggable'

// Constants
import { LANG_NAMES } from '@/lib/constants'

// Methods
import { 
  validateApiKey, 
  getActivity, 
  updateActivityContents,
  searchContents,
  getContentDetails,
} from '@/lib/functions'

// Graphic components
import ItemContent from '@/components/ItemContent.vue'
import DrawerContent from '@/components/DrawerContent.vue'
import LanguageWizard from '@/components/LanguageWizard.vue'
import SearchEngine from '@/components/SearchEngine.vue'
import SmileHeader from '@/components/SmileHeader.vue'
import SummaryListItem from '@/components/SummaryListItem.vue'

// Assets
import '@/assets/css/styles.css';

export default {

  // Component name.
  name: 'CatalogContents',

  // Components.
  components: {
    draggable,
    DrawerContent,
    ItemContent,
    LanguageWizard,
    SearchEngine,
    SmileHeader,
    SummaryListItem,
  },

  // Data.
  data () {
    return {
      // API key (client token).
      apiKey: null,

      // API token.
      token: null,

      // Activity id.
      activityId: null,

      // Whether the site is loaded in "edition" mode.
      // In such case, the component is "locked" (user cannot go back).
      editing: false,

      // Whether the edition process took place "partially".
      // This happens for example if there is some validation error.
      partial: false,

      // Whether the catalog is loaded to customize a path or course.
      customizing: false,

      // Whether page is fully loaded.
      loaded: false,

      // Chosen language.
      lang: null,

      // Whether search operation is taking place.
      searching: false,

      // Whether drag-and-drop operation is taking place.
      dragging: false,
      
      // Contents resulted by searching.
      resultContents: [],

      // Contents selected by the user.
      selectedContents: [],

      // Whether drawer must be shown.
      drawerShow: false,

      // Content ID to be displayed in the drawer.
      drawerContentId: null,
    }
  },

  // Code executed when the component is created.
  async created () {
    // Retrieves the GET parameters.
    // Might be only API key (when accessed from Moodle).
    // Can also include token and activityId (when redirected from another screen).
    this.apiKey = this.$route.query.apiKey
    this.token = this.$route.query.token
    this.activityId = parseInt(this.$route.query.activityId)
    this.customizing = this.$route.query.customizing === 'true'

    // Handle scroll event.
    window.onscroll = this.handleScroll

    // Listener for capturing Moodle events.
    window.onmessage = (event) => this.handleMoodle(event.data)

    // Validates the API key.
    if (!(await validateApiKey(this.apiKey))) this.$router.push('/no-license')

    // Site is finally loaded.
    this.loaded = true
    this.messageReady()
  },

  // Code executed for handling events when the page is unloaded.
  unmounted () {
    window.onscroll = null
  },

  // Triggers.
  watch: {

    // When the activity id is set, we retrieve it and load language and contents.
    async activityId (activityId) {
      if (this.activityId != null && !isNaN(this.activityId)) {
        const activity = await getActivity(this.apiKey, activityId)
        if (activity) {
          this.lang = activity.lang
          const contents = this.partial ? activity.contents_unsaved : activity.contents
          if (this.editing && Array.isArray(contents[0])) this.$router.push('/no-edit')
          if (this.editing) updateActivityContents(this.apiKey, this.activityId, this.lang, contents)
          this.selectedContents = await this.buildContents(contents ?? [])
        }
      }
    }
  },

  // Computed properties.
  computed: {

    /**
     * Nicely formatted language name.
     */
    langName () {
      return LANG_NAMES[this.lang]
    },

    /**
     * Returns whether the current content in the drawer
     * is already chosen (for styling purposes).
     */
    isDrawerContentSelected () {
      return this.isSelected(this.drawerContentId)
    }, 
  },

  // Methods.
  methods: {

    /**
     * Changes the language of the activity.
     */
    changeLang (lang) {
      this.lang = lang
    },

    /**
     * Whether a content has already been selected.
     */
    isSelected (content_id) {
      return this.selectedContents.map( c => c.id ).includes(content_id);
    },

    /**
     * Retrieve contents IDs from a list of contents.
     */
    getContentsIds (contents) {
      return contents.map( c => c.id )
    },

    /**
     * Build contents structure from contents IDs.
     */
    async buildContents (contents) {
      return Promise.all(contents.map( async c => await getContentDetails(this.token, this.lang, c) ))
    },

    /**
     * Select a content from the search results.
     */
    async selectContent (content) {
      this.selectedContents.push(await getContentDetails(this.token, this.lang, content.id));
      updateActivityContents(this.apiKey, this.activityId, this.lang, this.getContentsIds(this.selectedContents));
    },

    /**
     * Unselect a previously selected content.
     */
    unselectContent (content) {
      this.selectedContents = this.selectedContents.filter( c => c.id != content.id )
      updateActivityContents(this.apiKey, this.activityId, this.lang, this.getContentsIds(this.selectedContents));
    },

    /**
     * Syncs contents after drag and drop.
     */
    moveContent () {
      updateActivityContents(this.apiKey, this.activityId, this.lang, this.getContentsIds(this.selectedContents));
    },

    /**
     * Event executed when a content from the search results is clicked.
     */
    clickItem (content) {
      if (!this.isSelected(content.id)) this.selectContent(content);
      else this.unselectContent(content);
    },

    /**
     * Performs the search event.
     */
    async search (data) {
      this.searching = true
      this.resultContents = await searchContents(this.token, data)
      this.searching = false
    },

    /**
     * Shows or hides the drawer.
     */
    manageDrawer (content_id) {
      this.drawerContentId = content_id
      this.drawerShow = content_id !== null
    },

    /**
     * Messages to the parent when ready.
     */
    messageReady () {
      parent.postMessage({
        type: 'smile.lti.ready',
      }, '*')
    },

    /** 
     * Handle Moodle events.
     * This event will only occur when Moodle is in edition mode.
     * Therefore, we should prepare everything to allow activity edition.
     */
    handleMoodle (data) {
      if (data.type == 'smile.moodle') {
        this.token = data.token
        this.activityId = data.activityId
        this.editing = data.editing
        this.partial = data.partial
      }
    },

    /**
     * Handles the scroll of the site.
     */
    handleScroll () {      
      var header = document.getElementById("sl-search")
      var contents = document.getElementById("sl-contents")
      let height = header.offsetHeight
      if (contents) {
        if (window.pageYOffset > 278) {
          header.classList.add("sticky")
          contents.style.marginTop = height + 'px'
        } else {
          header.classList.remove("sticky")
          contents.style.marginTop = '0px'
        }
      }
    }
  }
}
</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3f87c7;
}
.catalog-message {
  max-width: 800px; 
  margin: 0 auto; 
  text-align: center;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 60px 0;
}
.contents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;
  row-gap: 30px;
}
.draggable {
  padding: 10px;
}
.unloaded {
  text-align: center; 
  margin: 20px;
}
.unloaded span {
  color: white; 
  padding: 10px 20px; 
  font-weight: 600; 
  border-radius: 10px;
}
.vertical-center {
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
</style>